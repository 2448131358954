import React from 'react'
import { Head } from '~/components'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import * as st from '~/assets/styl/404Sucesso.module.styl'
import img from '~/assets/img/img-sucesso.jpg'
import imgMobile from '~/assets/img/img-sucesso-mobile.jpg'

const Sucesso = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' })
  return (
    <>
      <Head />
      <main
        className={st.core}
        style={
          isMobile
            ? { background: `url(${imgMobile}) top right no-repeat` }
            : { background: `url(${img}) top center no-repeat` }
        }
      >
        <section className="container">
          <h1>Mensagem enviada com sucesso </h1>
          <p>
            Sua mensagem foi enviada para nossa central de atendimento. Em breve
            entraremos em contato.
          </p>
          <Link to="/" className="btn-blue">
            Voltar
          </Link>
        </section>
      </main>
    </>
  )
}

export default Sucesso
